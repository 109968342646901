import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { NavBar, OvalLoading, AppErrorBoundry, PageNotFound } from "../../components";
import { LinearProgress } from '@mui/material';
import { userActions, getCurrentUser, getAlert, commonAction, isProgress, isAdmin, isArchitect, encyptDataObject } from "../../store";

const ConsoleEndPoint = `https://console.${process.env.REACT_APP_MAIN_DOMAIN}`;

export const PageNotFoundScreen = (props) => {
  const navigate = useNavigate();
  return <PageNotFound onHome={() => navigate("/")} />
}

export const HomeScreen = (props) => {
  const { notification, modal } = App.useApp();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(getCurrentUser);
  const appAlert = useSelector(getAlert)
  const showProgress = useSelector(isProgress);
  const navigateToLogin = () => {
    const redirectData = {};
    const { pathname, search } = location;
    if (pathname !== '') {
      redirectData.path = pathname;
    }
    if (search !== '') {
      redirectData.search = search;
    }
    let path = ConsoleEndPoint + '/login?source=educator';
    if (Object.keys(redirectData).length > 0) {
      let redirect = encyptDataObject(redirectData)
      path += `&redirect=${redirect}`
    }
    window.location.assign(path);
  }
  useEffect(() => {
    document.title = 'Educator | Fusion Demo';
    dispatch(userActions.validateToken())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (appAlert) {
      if (appAlert.type === 'modal') {
        let modalConfig = { title: appAlert.message, content: appAlert.description, okText: 'OK', onOk: clearNotification }
        if (appAlert.severity === 'warning') {
          modal.warning(modalConfig)
        } else if (appAlert.severity === 'error') {
          modal.error(modalConfig)
        } else if (appAlert.severity === 'success') {
          modal.success(modalConfig)
        }
      } else {
        const config = { duration: 2, message: appAlert.message, onClose: clearNotification }
        if (appAlert.success === false) {
          notification.error(config)
        } else {
          notification.success(config)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appAlert]);
  useEffect(() => {
    if (user === 'Error') {
      navigateToLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const clearNotification = (e, reason) => {
    if (reason !== 'clickaway') {
      dispatch(commonAction.setAlert(null));
      if (appAlert.type === 'modal') {
        Modal.destroyAll()
      }
    }
  }
  return (
    <div className='col w-100 h-100 screen o-hide'>
      {
        Boolean(user && user !== 'Error') ?
          <React.Fragment>
            <NavBar currentModule='educator' user={user} isAdmin={isAdmin(user)} isArchitect={isArchitect(user)} />
            <LinearProgress classes={{ root: `api-progress c1E686D ${showProgress ? 'show' : ''}` }} color="inherit" />
            <main className='col flex-full o-hide'>
              <AppErrorBoundry>
                {props.children}
              </AppErrorBoundry>
            </main>
          </React.Fragment>
          :
          <OvalLoading className='col w-100 h-100' />
      }
    </div>
  )
}
